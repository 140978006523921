<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Lesson
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(handleCreateSubmit)"
          
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Lesson Title"
            rules="required"
          >
            <b-form-group
              label="Lesson Title"
              label-for="course-name"
            >
              <b-form-input
                id="full-name"
                v-model="localOptions.title"
                autofocus
                :state="localOptions.title!=null"
                trim
                placeholder="Awesome lesson"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

         

        
 
 
   <validation-provider
            #default="validationContext"
            name="Lesson Prefix "
           
          >
            <b-form-group
              label="Lesson Prefix"
              label-for="course-name"
            >
              <b-form-input
                id="full-name"
                v-model="localOptions.prefix"
                autofocus
            
                trim
                placeholder="Lesson"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

            <validation-provider
            #default="validationContext"
            name="Lesson Duration "
           
          >
            <b-form-group
              label="Lesson Duration"
              label-for="course-name"
            >
              <b-form-input
                id="full-name"
                v-model="localOptions.estimatedDurationMinutes"
                autofocus
            
                trim
                placeholder="120"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


              <validation-provider
            #default="validationContext"
            name="Lesson Order "
           
          >
            <b-form-group
              label="Lesson Order"
              label-for="course-name"
            >
              <b-form-input
                id="full-name"
                v-model="localOptions.order"
                autofocus
                
                trim
                placeholder="2"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- User Role -->
        
             <validation-provider
            #default="validationContext"
            name="Lesson Type "
               rules="required"
          >
         <b-form-group
              label="Lesson Type"
              label-for="user-role"
              :state="localOptions.lessonType === null ? false : true"
            >
               <course-type-picker
             :initialCategories="localOptions.lessonType"
              @selectionChanged="lessonTypeUpdated"
            />
                <b-form-input
                id="lessonType-name"

                v-model="localOptions.lessonType"
                 hidden
              />
             

             <b-form-invalid-feedback :state="validationContext.errors.length > 0 ? false:null">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>

             
            </b-form-group>
        
   </validation-provider>

     

        

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'

import router from '@/router'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import CourseTypePicker from '../components/LectureLessonsTypePicker.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    CourseTypePicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    activeLesson:null,
  
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      localOptions:{
        title:null,
        prefix:null,
        estimatedDurationMinutes:5,
        order:1,
        lessonType:null,


      }
    }
  },
   watch: {
  activeExam(newVal,oldVal){
    console.log("active lesson changed");
      if(newVal){
          console.log("active lesson not null");
        this.localOptions=newVal;
        console.log("exam updated");
        console.log(this.localExam);
     
       
      }
    },
   
   },
  methods:{

 


    resetForm(){
      this.localOptions={
        title:null,
              prefix:null,
        estimatedDurationMinutes:5,
        order:1,
        lessonType:null,

    }
    },
   handleCreateSubmit(){
    {
        console.log("course ADD PAGE  Create triggered");
   
     const incomingcourse= router.currentRoute.params.id;
     console.log("incomingcourse"+incomingcourse);
 this.localOptions.courseguid=incomingcourse;
   this.localOptions.lectureid=router.currentRoute.params.lectureid;

      store.dispatch('course-lecture-lessons/addCourse',  this.localOptions)
        .then(() => {
        
 
          this.$emit('refetch-data')
          this.$emit('lesson-created')
          this.$emit('update:is-add-new-user-sidebar-active', false)
        })
    }
   },
    lessonTypeUpdated(payload) {
      console.log("lessonTypeUpdated triggered ,Lesson result in info ");
      console.log(payload);
      if (payload==null)
      {
           this.localOptions.lessonType =null;
      
      }   else{
         this.localOptions.lessonType = payload.typekey;
    } 
    },
  

  
  },
  setup(props, { emit }) {

    return;
    const blankUserData = {
    
      title: '',
      courseCode: '',
     
      coursemode:'',
   
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
        console.log("course ADD PAGE  Create triggered");
   
     const incomingcourse= router.currentRoute.params.id;
     console.log("incomingcourse"+incomingcourse);
 userData.value.courseguid=incomingcourse;
  userData.value.lectureid=router.currentRoute.params.lectureid;

      store.dispatch('course-lecture-lessons/addCourse', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
